import React from 'react';
import { RawIntlProvider } from 'react-intl';
import { setLocale } from 'yup';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale-provider';
/**
 * @debt frontend "find a way to dynamically import locales"
 */
import frFR from 'antd/es/locale/fr_FR';
import 'dayjs/locale/fr';

import {
  intl,
  NAVIGATOR_LOCALE,
  NAVIGATOR_LANGUAGE,
  DIRECTION,
} from 'services/i18n';

/**
 * Include both exact locale file (fr-FR for exemple) as well as language fallback (if en-CA is asked, falling back to en value)
 */
const antdLocales: Record<string, Locale> = {
  fr: frFR,
  'fr-FR': frFR,
};

export const IntlConfig: React.FC = ({ children }) => {
  setLocale({
    mixed: {
      default: intl.formatMessage({ id: 'validation.default' }),
      required: intl.formatMessage({ id: 'validation.required' }),
    },
    string: {
      email: intl.formatMessage({ id: 'validation.email' }),
      length: intl.formatMessage({ id: 'validation.invalidLength' }),
      matches: intl.formatMessage({ id: 'validation.invalidFormat' }),
    },
  });
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);
  dayjs.locale(NAVIGATOR_LANGUAGE);

  return (
    <ConfigProvider
      locale={
        antdLocales[NAVIGATOR_LOCALE] ?? antdLocales[NAVIGATOR_LANGUAGE] ?? frFR
      }
      direction={DIRECTION}
    >
      <RawIntlProvider value={intl}>
        <>{children}</>
      </RawIntlProvider>
    </ConfigProvider>
  );
};
