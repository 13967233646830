import { Scope } from 'backend/types/scope';

import { useUser } from './useUser';

export const useScope = (): Scope | undefined => {
  const user = useUser();

  if (!user) {
    return user || undefined;
  }

  return user.claims.scope || Scope.CO_OWNER;
};
