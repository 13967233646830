import * as yup from 'yup';
import { A } from 'ts-toolbelt';

import { voteSchema } from '../schemas/vote';

export enum VoteChoice {
  IN_FAVOR = 'IN_FAVOR',
  AGAINST = 'AGAINST',
  ABSTENTION = 'ABSTENTION',
  NOT_EXPRESSED = 'NOT_EXPRESSED',
}

export enum VoteMethod {
  UNKNOWN = 'UNKNOWN',
  EARLY_POSTAL_VOTE = 'EARLY_POSTAL_VOTE',
  EARLY_ONLINE_VOTE = 'EARLY_ONLINE_VOTE',
  PHYSICALLY_PRESENT = 'PHYSICALLY_PRESENT',
  REMOTELY_PRESENT = 'REMOTELY_PRESENT',
  REPRESENTED = 'REPRESENTED',
}

export enum VotePresency {
  VOTING = 'VOTING',
  NOT_VOTING = 'NOT_VOTING',
}

export type Vote = A.Compute<yup.Asserts<typeof voteSchema>>;
