export enum ValidationErrorCode {
  MISSING = 'field_missing',
  INVALID = 'field_invalid',
  INVALID_TYPE = 'invalid_type',
  DUPLICATE_ENTRY = 'duplicate_entry',
  INVALID_ENTRY = 'invalid_entry',
  MISSING_ENTRY = 'missing_entry',
  INVALID_ALLOCATION_KEY = 'invalid_allocation_key',
  INVALID_RESOLUTION = 'invalid_resolution',
  DUPLICATE_EMAIL = 'duplicate_email',
  DUPLICATE_CO_OWNER = 'duplicate_co_owner',
}

export enum ReportingErrorState {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  NOTICE = 'NOTCIE',
  PARSE = 'PARSE',
}
