import { createGlobalState } from 'react-use';
import type firebase from 'firebase';

import { UserClaims } from 'backend/types/userClaims';

type UserWithTokenAndClaims = firebase.User & {
  token: string;
  claims: UserClaims;
};

export const useUserState = createGlobalState<
  UserWithTokenAndClaims | null | undefined
>();

export const useUser = (): UserWithTokenAndClaims | null | undefined => {
  const [user] = useUserState();

  return user;
};
