import { VoteChoice, VotePresency, NotificationStatus } from 'backend/types';

import antdTheme from './theme.json';

export const theme = {
  ...antdTheme,
};

export const colorPalette = {
  black: '#000000',
  white: theme['@white'],
  votimBlue: theme['@votim-blue'],
  primaryColor: theme['@primary-color'],
  secondaryColor: theme['@secondary-color'],
  blushPink: '#FF78E1',
  conifer: '#9fd85b',
  green: '#21991d',
  red: '#ff3434',
  lighterGrey: '#f8f8f8',
  lightGrey: '#d3d3d3',
  mediumGrey: theme['@medium-grey'],
  gold: '#FFD700',
  limegreen: '#32CD32',
};

export const colorUsage = {
  title: {
    important: {
      color: colorPalette.primaryColor,
    },
    divider: colorPalette.primaryColor,
  },
  text: {
    neutral: colorPalette.votimBlue,
    important: {
      color: colorPalette.primaryColor,
    },
  },
  icon: {
    status: {
      danger: colorPalette.red,
      success: colorPalette.conifer,
      neutral: colorPalette.votimBlue,
    },
  },
  notificationStatus: {
    [NotificationStatus.NOT_APPLICABLE]: colorPalette.lightGrey,
    [NotificationStatus.SUCCESS]: colorPalette.green,
    [NotificationStatus.ERROR]: colorPalette.red,
  },
  voteStatus: {
    remaining: colorPalette.mediumGrey,
    done: colorPalette.primaryColor,
    [VotePresency.VOTING]: colorPalette.mediumGrey,
    [VotePresency.NOT_VOTING]: colorPalette.lightGrey,
    [VoteChoice.IN_FAVOR]: colorPalette.green,
    [VoteChoice.AGAINST]: colorPalette.red,
    [VoteChoice.ABSTENTION]: colorPalette.gold,
    [VoteChoice.NOT_EXPRESSED]: colorPalette.votimBlue,
  },
  header: {
    background: `linear-gradient(to right, ${colorPalette.red}, ${colorPalette.blushPink})`,
  },
  underline: {
    background: `linear-gradient(to left, ${colorPalette.blushPink}, ${colorPalette.red} 100%)`,
    backgroundPosition: '0 100%',
    backgroundSize: '100% 2px',
    backgroundRepeat: 'repeat-x',
  },
  background: {
    gradient: `linear-gradient(${colorPalette.blushPink}, ${colorPalette.red})`,
  },
  button: {
    yes: {
      backgroundColor: colorPalette.green,
      borderColor: colorPalette.green,
      color: colorPalette.white,
    },
    no: {
      backgroundColor: colorPalette.red,
      borderColor: colorPalette.red,
      color: colorPalette.white,
    },
  },
  video: {
    backgroundColor: colorPalette.black,
  },
  section: {
    border: colorPalette.lightGrey,
  },
  resolutions: {
    OPEN: colorPalette.gold,
    CLOSED_COMMENT: colorPalette.green,
    CLOSED_RESOLUTION: {
      ACCEPTED: colorPalette.green,
      REFUSED: colorPalette.red,
    },
  },
  grid: {
    cell: {
      borderColor: colorPalette.lightGrey,
    },
  },
  chatMessage: {
    self: colorPalette.lighterGrey,
    others: colorPalette.white,
  },
};

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;

export const fontSize = {
  small: '12px',
  medium: '16px',
  large: '20px',
};

export const border = {
  sections: `solid 1px ${colorUsage.section.border}`,
};
