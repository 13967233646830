import * as yup from 'yup';
import { A } from 'ts-toolbelt';

import { resolutionSchema } from '../schemas/resolution';
import { VoteChoice } from './vote';

export enum ResolutionType {
  GENERAL = 'GENERAL',
  PRESIDENT = 'PRESIDENT',
  SECRETARY = 'SECRETARY',
  COMMENT = 'COMMENT',
  SCRUTINEER = 'SCRUTINEER',
  UNION_CONCIL = 'UNION_CONCIL',
}

export enum ResolutionMajorityArticle {
  MAJORITY_ARTICLE_24 = 'MAJORITY_ARTICLE_24',
  MAJORITY_ARTICLE_25 = 'MAJORITY_ARTICLE_25',
  MAJORITY_ARTICLE_26 = 'MAJORITY_ARTICLE_26',
}

export enum ResolutionVoteStatus {
  PENDING = 'PENDING',
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
}

export enum ResolutionVoteResult {
  ACCEPTED = 'ACCEPTED',
  REFUSED = 'REFUSED',
}

export type Resolution = A.Compute<yup.Asserts<typeof resolutionSchema>>;

export type VoteCounts = {
  [VoteChoice.IN_FAVOR]: number;
  [VoteChoice.AGAINST]: number;
  [VoteChoice.ABSTENTION]: number;
};
