import React from 'react';
import { FuegoProvider } from '@nandorojo/swr-firestore';
import { BrowserRouter } from 'react-router-dom';
import Div100vh from 'react-div-100vh';

import { Auth } from 'components/utils/Auth';
import { IntlConfig } from 'components/utils/IntlConfig';
import { Routing } from 'components/utils/Routing';
import { fuego } from 'services/firebase/fuego';

import './App.less';

const App: React.FunctionComponent = () => (
  <BrowserRouter>
    <IntlConfig>
      <FuegoProvider fuego={fuego}>
        <Auth>
          <Div100vh>
            <Routing />
          </Div100vh>
        </Auth>
      </FuegoProvider>
    </IntlConfig>
  </BrowserRouter>
);

export default App;
