import keys from 'lodash/keys';
import { createIntl, createIntlCache } from 'react-intl';

import { fr } from './fr';

type Message = string | NestedDictionary;
interface NestedDictionary {
  [x: string]: Message;
}
interface FlattenedDictionary {
  [x: string]: string;
}

const flattenMessages = (
  nestedMessages: NestedDictionary,
  prefix = '',
): FlattenedDictionary =>
  keys(nestedMessages).reduce((messages: FlattenedDictionary, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value;
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});

const translations: Record<string, FlattenedDictionary> = {
  fr: flattenMessages(fr),
};

export const NAVIGATOR_LOCALE = window.navigator.language;
export const [NAVIGATOR_LANGUAGE] = NAVIGATOR_LOCALE.split('-');
export const DIRECTION = NAVIGATOR_LANGUAGE === 'ar' ? 'rtl' : 'ltr';
export const cache = createIntlCache();
export const intl = createIntl(
  {
    locale: NAVIGATOR_LOCALE,
    messages:
      NAVIGATOR_LOCALE in translations
        ? translations[NAVIGATOR_LOCALE]
        : NAVIGATOR_LANGUAGE in translations
        ? translations[NAVIGATOR_LANGUAGE]
        : translations.fr,
  },
  cache,
);
