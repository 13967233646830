import React from 'react';
import { Row, Spin } from 'antd';

import { getSpacing } from 'stylesheet';

export const Loader: React.FC = () => (
  <Row style={{ width: '100%', padding: getSpacing(10) }} justify="center">
    <Spin />
  </Row>
);
