import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';

import { useUserState } from 'hooks/useUser';
import { Paths } from 'components/utils/Routing';
import { UserClaims } from 'backend/types/userClaims';
import { fuego } from 'services/firebase/fuego';

export const Auth: React.FC = ({ children }) => {
  const { push } = useHistory();
  const location = useLocation();
  const [user, setUser] = useUserState();

  useEffect(() => {
    fuego.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        setUser(user);
        return;
      }

      const { token, claims } = await user.getIdTokenResult(true);
      setUser({ ...user, token, claims: claims as UserClaims });
    });
  }, [setUser]);

  useEffect(() => {
    if (
      user === null &&
      location.pathname !== Paths.LOGIN &&
      location.pathname !== Paths.REGISTRATED_LOGIN
    ) {
      const loginUrl = new URL([window.location.origin, Paths.LOGIN].join(''));

      if (
        location.pathname &&
        location.pathname !== '/' &&
        location.href &&
        location.origin
      ) {
        const b64PrevPath = btoa(location.href.replace(location.origin, ''));
        loginUrl.searchParams.append('prevPath', b64PrevPath);
      }

      push([loginUrl.pathname, loginUrl.search].join(''));
    }
  }, [user, push, location]);

  return <>{children}</>;
};
